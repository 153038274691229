import CreateClipsRequestDto from "./CreateClipsRequestDto";
import CreateClipsResponseDto from "./CreateClipsResponseDto";
import FilmServiceClient from "../FilmServiceClient";
import {message} from "antd";
import ClipDto from "./ClipDto";
import DeleteResponse from "../../types/deleteresponse/DeleteResponse";

const createClip = async (clip: ClipDto) => {
  let result: ClipDto | null = null

  try {
    const resp = await FilmServiceClient.post<ClipDto>('clip', clip)

    result = resp.data
  } catch (err: any) {
    message.error('There was an issue creating this clip.')
  }

  return result
}

const createClips = async (request: CreateClipsRequestDto) => {

  let result: ClipDto[] | null = null

  try {
    const resp = await FilmServiceClient.post<CreateClipsResponseDto>('/clips', {...request});

    resp.data.results?.forEach(result => result.errorMessages.map(errorMessage => message.error(errorMessage)))

    result = resp.data.results?.filter(result => result.successful)
                 .map(result => {
        result.errorMessages.map(errorMessage => message.error(errorMessage))

                   return result.value;
                 })

  } catch (err: any) {
    message.error('There was an issue creating clips.')
  }

  return result
}

const readClip = async (clipId: string) => {

  let result: ClipDto | null = null

  try {
    const resp = await FilmServiceClient.get<ClipDto>(`clip/${clipId}`)

    result = resp.data
  } catch (err: any) {
    message.error('There was an issue reading this clip.')
  }

  return result
}

const readClips = async () => {

  let result: ClipDto[] | null = null

  try {
    const resp = await FilmServiceClient.get<ClipDto[]>('/clips')

    result = resp.data

  } catch (err: any) {
    message.error('There was an issue reading clips.')
  }

  return result
}

const readClipWithDownloadLink = async (id: string) => {
  let result: ClipDto | null = null

  try {
    const resp = await FilmServiceClient.get<ClipDto>(`/clip/${id}/download`)

    result = resp.data
  } catch (err: any) {
    message.error('There was an issue rendering this clip.')
  }

  return result
}

const updateClip = async (clip: ClipDto) => {
  let result: ClipDto | null = null

  try {
    const resp = await FilmServiceClient.put<ClipDto>(
      'clip',
      clip
    )

    result = resp.data
  } catch (err: any) {
    message.error('There was an issue updating this clip.')
  }

  return result
}

const deleteClip = async (id: string) => {
  let result = false

  try {
    const resp = await FilmServiceClient.delete<DeleteResponse>(`/clip/${id}`)

    result = resp.data.isSuccessful
  } catch (err: any) {
    message.error('There was an issue deleting this clip.')
  }

  return result
}

const deleteClips = async (ids: string[]) => {
  let result = false

  try {
    const resp = await FilmServiceClient.post<DeleteResponse>(
      `/clips/delete`,
      {
        ids
      }
    )

    result = resp.data.isSuccessful
  } catch (err: any) {
    message.error('There was an issue deleting clips.')
  }

  return result
}

const ClipService = {
  createClip,
  createClips,
  readClip,
  readClips,
  readClipWithDownloadLink,
  updateClip,
  deleteClip,
  deleteClips
}

export default ClipService;
