import {
  Button, Card, Col, Empty, Form, Input, Radio, RadioChangeEvent, Row, Select, Space, Table, Typography
} from "antd";
import React from "react";
import useClipsModel from "./ClipsModel";
import {AppstoreOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {ColumnsType} from "antd/es/table";
import Clip from "../../types/clip/Clip";
import TagList from "../../components/TagList/TagList";
import Tag from "../../types/tag/Tag";
import {useQuery} from "react-query";
import TagService from "../../services/tag/TagService";

const {Meta} = Card;

const {Text} = Typography

const {Option} = Select

const generateColumns: (userTags: Tag[]) =>  ColumnsType<Clip> = (userTags: Tag[]) => [
  {
    title: 'Title',
    dataIndex: 'title',
    sorter: (a: Clip, b: Clip) => a.title < b.title ? -1 : 1,
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    render: (tags: Tag[]) => <TagList tags={tags} onTagsChange={() => {}}></TagList>

  }
]

function Clips() {

  const {
    clips,
    handleCreateClip,
    handleClickClipCard,
    handleClickEdit,
    handleClickDelete,
    handleClickCancel,
    isEditing,
    selectedClipIds,
    activeView,
    handleActiveViewChange,
    handleFormValuesChange
  } = useClipsModel()



  const {data: tagOptions} = useQuery('tagOptions', TagService.getAll)

  return (<>
    <Card>
      <Space
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 16
        }}
      >
        <Radio.Group
          defaultValue={'grid'}
          onChange={(e: RadioChangeEvent) => handleActiveViewChange(e.target.value)}
        >
          <Radio.Button value={'grid'}><AppstoreOutlined/></Radio.Button>
          <Radio.Button value={'table'}><UnorderedListOutlined/></Radio.Button>
        </Radio.Group>
        <Space>
          {!isEditing && <Button
              onClick={handleClickEdit}
              style={{
                width: '100%'
              }}
              type="primary"
          >Edit</Button>}
          {isEditing && <>
              <Button
                  onClick={handleClickCancel}
                  style={{
                    width: '100%'
                  }}
                  type="ghost"
                  danger
              >Cancel</Button>
              <Button
                  icon={<DeleteOutlined/>}
                  onClick={handleClickDelete}
                  style={{
                    width: '100%'
                  }}
                  type="primary"
                  danger
                  disabled={selectedClipIds.length < 1}
              >Delete</Button>
          </>}
        </Space>
      </Space>
      <Row
          style={{
            width: 1152
          }}
          gutter={[16, 16]}
      >
          <Col span={24}>
            <Form layout="inline" onValuesChange={handleFormValuesChange}>
                <Form.Item
                  name="search"
                >
                  <Input prefix={<SearchOutlined/>} placeholder="Title" allowClear></Input>
                </Form.Item>
                <Form.Item
                  name="tags"
                >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{width: 300}}
                      placeholder="Tags"
                    >
                      {tagOptions && tagOptions.map(t => <Option key={t.name} value={t.name}>{t.name}</Option>)}
                    </Select>
                </Form.Item>
            </Form>
          </Col>
          {activeView === 'grid' && (
            <>

              <Col span={6}>
                <Button
                  icon={<PlusOutlined/>}
                  style={{
                    height: 220,
                    width: 272,
                    display: 'flex',
                    alignItems: "center",
                    justifyContent: 'center'
                  }}
                  onClick={handleCreateClip}
                >
                </Button>
              </Col>
              {clips.map(c => {

                let thumbnail

                if (c.bucketObject?.thumbnailGetLink) {
                  thumbnail = <img
                    src={c.bucketObject?.thumbnailGetLink}
                    alt=""
                  />
                } else if (c.sourceBucketObject?.thumbnailGetLink) {
                  thumbnail = <img
                    src={c.sourceBucketObject?.thumbnailGetLink}
                    alt=""
                  />
                } else {
                  thumbnail = <Empty
                    description="Thumbnails coming soon!"
                    style={{
                      margin: 0
                    }}
                  />
                }

                return (<Col span={6}>
                  <Card
                    cover={thumbnail}
                    className={selectedClipIds.find(id => c.id === id) && 'clip-card-selected'}
                    hoverable
                    onClick={() => c.id && handleClickClipCard(c.id)()}
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end', ...(selectedClipIds.find(id => c.id ===
                        id) ? {border: '#48b89a 4px solid'} : {})
                    }}
                  >
                    <Meta
                      title={
                        <Space direction="vertical">
                          {c.title && c.title !== '' ? c.title : 'Click to add a title'}
                          <TagList tags={c.tags} onTagsChange={() => {}}></TagList>
                          {
                            (!c.tags || c.tags.length === 0) &&
                              <Text disabled italic>No Tags</Text>
                          }
                        </Space>
                      }
                    />
                  </Card>
                </Col>)
              })}
            </>
          )}
      </Row>
      {activeView === 'table' && <Table
          columns={tagOptions ? generateColumns(tagOptions.map(t => new Tag(t))) : []}
          dataSource={clips}
          onRow={(record: Clip, index?: number) => {
            return {
              onClick: () => record.id && handleClickClipCard(record.id)()
            }
          }}
          style={{width: 1138}}
          pagination={false}
      />}
    </Card>
  </>)
}

export default Clips;
