import FilmServiceClient from "../FilmServiceClient";
import UserDto from "./UserDto";

const getUserDetails = async () => {

  let user: UserDto | null = null;

  try {
    user = (await FilmServiceClient.get<UserDto>('/user')).data
  } catch (err: any) {

  }

  return user;
}

const confirmEmail = async (token: string) => {

  let user: UserDto | null = null

  try {
    const resp = await FilmServiceClient.post<UserDto>('/user/confirm-email', {token})

    user = resp.data
  } catch (err: any) {

  }

  return user

}

const resendConfirmationEmail = async () => {
  try {
    await FilmServiceClient.post('/user/resend-confirmation-email')
  } catch (e) {

  }
}

const UserService = {
  getUserDetails,
  confirmEmail,
  resendConfirmationEmail
}
export default UserService;
