import Konva from "konva";
import React, {MutableRefObject, useCallback, useContext, useEffect, useRef, useState} from "react";
import {LayerShape} from "../WhiteboardModel";
import shortid from "shortid";
import {RGBColor} from "react-color";
import AnnotationShape, {AnnotationShapeType} from "../../../../types/annotation/AnnotationShape";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {UserPreferencesContext} from "../../../../contexts/userpreferences/UserPreferencesContext";
import UserPreferences from "../../../../types/userpreferences/UserPreferences";
import ColorPalette from "../../../../types/userpreferences/ColorPalette";

const createNewPointLine = (shape: AnnotationShape, pointerPosition: Konva.Vector2d, forceEndpoint: boolean) => {

  if (forceEndpoint) {
    const newPointLinePoints = shape.points.concat()

    newPointLinePoints.splice(
      shape.points.length,
      0,
      pointerPosition.x,
      pointerPosition.y
    )

    return new AnnotationShape({
      id: shortid(),
      type: AnnotationShapeType.NEW_POINT_LINE,
      points: [...newPointLinePoints, pointerPosition.x, pointerPosition.y],
      color: {
        ...shape.color,
        a: 0.3
      },
      tension: shape.tension,
      strokeWidth: shape.strokeWidth
    });
  }

  let closestPoint: number[] = [0, 0, Number.POSITIVE_INFINITY, -1]
  let secondClosestPoint: number[]

  for (let i = 0; i < shape.points.length / 2; i++) {
    const dist = Math.hypot(
      pointerPosition.y - shape.points[2 * i + 1],
      pointerPosition.x - shape.points[2 * i]
    )

    if (dist < closestPoint[2]) {
      closestPoint = [shape.points[i * 2], shape.points[i * 2 + 1], dist, i]
    }
  }

  if (closestPoint[3] - 1 < 0 || Math.hypot(
    pointerPosition.y - shape.points[2 * (closestPoint[3] - 1) + 1],
    pointerPosition.x - shape.points[2 * (closestPoint[3] - 1)]
  ) > Math.hypot(
    pointerPosition.y - shape.points[2 * (closestPoint[3] + 1) + 1],
    pointerPosition.x - shape.points[2 * (closestPoint[3] + 1)]
  )) {
    secondClosestPoint = [
      shape.points[2 * (closestPoint[3] + 1)],
      shape.points[2 * (closestPoint[3] + 1) + 1],
      Math.hypot(
        pointerPosition.y - shape.points[2 * (closestPoint[3] + 1) + 1],
        pointerPosition.x - shape.points[2 * (closestPoint[3] + 1)]
      ),
      closestPoint[3] + 1
    ]
  } else {
    secondClosestPoint = [
      shape.points[2 * (closestPoint[3] - 1)],
      shape.points[2 * (closestPoint[3] - 1) + 1],
      Math.hypot(
        pointerPosition.y - shape.points[2 * (closestPoint[3] - 1) + 1],
        pointerPosition.x - shape.points[2 * (closestPoint[3] - 1)]
      ),
      closestPoint[3] - 1
    ]
  }

  const isNewEndpoint = Math.hypot(
    closestPoint[1] - secondClosestPoint[1],
    closestPoint[0] - secondClosestPoint[0]
  ) - secondClosestPoint[2] < 0

  let addPointLine

  if (isNewEndpoint) {

    const newPointLinePoints = shape.points.concat()

    newPointLinePoints.splice(
      closestPoint[3] === 0 ? 0 : (closestPoint[3] + 1) * 2,
      0,
      pointerPosition.x,
      pointerPosition.y
    )

    addPointLine = new AnnotationShape({
      id: shortid(),
      type: AnnotationShapeType.NEW_POINT_LINE,
      points: [...newPointLinePoints, pointerPosition.x, pointerPosition.y],
      color: {
        ...shape.color,
        a: 0.3
      },
      tension: shape.tension,
      strokeWidth: shape.strokeWidth
    });

  } else {

    const newPointLinePoints = shape.points.concat()

    newPointLinePoints.splice(closestPoint[3] > secondClosestPoint[3] ? (secondClosestPoint[3] + 1) *
        2 : (closestPoint[3] + 1) * 2,
      0,
      pointerPosition.x,
      pointerPosition.y
    )

    addPointLine = new AnnotationShape({
      id: shortid(),
      type: AnnotationShapeType.NEW_POINT_LINE,
      points: [...newPointLinePoints, pointerPosition.x, pointerPosition.y],
      color: {
        ...shape.color,
        a: 0.3
      },
      tension: shape.tension,
      strokeWidth: shape.strokeWidth
    });

  }

  return addPointLine
}

const isWithinBounds = (e: React.MouseEvent, div: HTMLDivElement) => {
  return e.clientX > div.getBoundingClientRect().x && e.clientY > div.getBoundingClientRect().y && e.clientX < (div.getBoundingClientRect().x + div.getBoundingClientRect().width) && e.clientY < (div.getBoundingClientRect().y + div.getBoundingClientRect().height)
}

const useWhiteboardLayerModel = (layerId: string,
  shapes: AnnotationShape[],
  tool: AnnotationShapeType,
  onShapesChange: (shapes: AnnotationShape[]) => void,
  getImageUrlRef?: MutableRefObject<(() => string) | null>,
  stageMapRef?: MutableRefObject<Map<string, React.RefObject<Konva.Stage>>>,
  isEditable?: boolean
) => {

  const [selectedShapeId, setSelectedShapeId] = React.useState<string | null>(null)
  const isDrawing = React.useRef(false);
  const stageRef = useRef<Konva.Stage>(null);
  const stageContainerRef = useRef<HTMLDivElement>(null)
  const [eraserPosition, setEraserPosition] = useState<{ x: number, y: number } | null>(null)
  const [color, setColor] = useState<RGBColor>({
    r: 255,
    g: 0,
    b: 0,
    a: 1
  })
  const [isAddingPoint, setIsAddingPoint] = useState(false)
  const forceEndRef = useRef<boolean>(false);
  stageMapRef?.current.set(layerId, stageRef);
  const [guideLines, setGuideLines] = useState<{ vertical: number[], horizontal: number[] }>({
    vertical: [],
    horizontal: []
  })

  const {userPreferences, setUserPreferences} = useContext(UserPreferencesContext)

  const handleMouseDown = (event: Konva.KonvaEventObject<MouseEvent>) => {

    if (isDrawing.current && tool === AnnotationShapeType.ZONE) {

      isDrawing.current = false

      return
    } else if (isDrawing.current && tool === AnnotationShapeType.CIRCLE) {

      isDrawing.current = false
      return
    } else if (isDrawing.current && tool === AnnotationShapeType.TEXT) {
      isDrawing.current = false
      return
    }

    if (isAddingPoint) {

      const stage = event.target.getStage()

      const shape = shapes.find(s => s.id === selectedShapeId)

      const newPointLine = shapes.find(s => s.type === AnnotationShapeType.NEW_POINT_LINE)

      const pointerPosition = stage?.getPointerPosition()

      if (!pointerPosition || !stage || !shape || !newPointLine) {
        return
      }

      shape.points = newPointLine.points.slice(
        0,
        newPointLine.points.length - 2
      )

      onShapesChange(shapes.concat())

      return
    }

    if (event.target !== event.target.getStage() && tool !== AnnotationShapeType.ERASER) {
      return
    }

    setSelectedShapeId(null)

    const pos = event.target.getStage()
                     ?.getPointerPosition();

    const newShape = new AnnotationShape({
      id: shortid(),
      type: tool,
      points: [pos!.x, pos!.y, ...(tool === AnnotationShapeType.TEXT ? [pos!.x + 100, pos!.y] : [])],
      color: color, ...(tool === AnnotationShapeType.BLOCK ? {blockAngle: 0} : {}),
      ...(tool === AnnotationShapeType.ARROW || tool === AnnotationShapeType.BLOCK || tool === AnnotationShapeType.LINE || tool === AnnotationShapeType.NEW_POINT_LINE ? {tension: userPreferences?.lineTension} : {}),
      strokeWidth: userPreferences?.strokeWidth || 3, ...(tool === AnnotationShapeType.TEXT ? {
        text: 'test',
        fontSize: userPreferences?.fontSize || 24
      } : {})
    })

    if ((tool === AnnotationShapeType.LINE || tool === AnnotationShapeType.BLOCK || tool ===
      AnnotationShapeType.ARROW) && !isAddingPoint) {
      forceEndRef.current = true
      setIsAddingPoint(true)
      setSelectedShapeId(newShape.id)
    } else if (tool === AnnotationShapeType.TEXT) {
      setSelectedShapeId(newShape.id)
    } else {
      isDrawing.current = true
      setSelectedShapeId(newShape.id)
    }

    onShapesChange([
      ...shapes, newShape
    ]);
  };

  const handleMouseMove = (event: Konva.KonvaEventObject<MouseEvent>) => {

    const stage = event.target.getStage()

    if (!stage) {
      return
    }

    const pointerPosition = stage.getPointerPosition()

    if (!pointerPosition) {
      return
    }

    if (tool === AnnotationShapeType.ERASER) {

      setEraserPosition({
        x: pointerPosition.x,
        y: pointerPosition.y
      })
    }

    const newGuideLines = shapes.filter(s => s.type !== AnnotationShapeType.NEW_POINT_LINE)
                                .map(s => s.points)
                                .reduce(
                                  (curr, acc) => acc.concat(curr),
                                  []
                                )
                                .map((pt, i) => {
                                  if (i % 2 === 0 && Math.abs(pointerPosition.x - pt) < 20) {
                                    return {
                                      vertical: [pt],
                                      horizontal: []
                                    }
                                  } else if (i % 2 === 1 && Math.abs(pointerPosition.y - pt) < 20) {
                                    return {
                                      vertical: [],
                                      horizontal: [pt]
                                    }
                                  }
                                  return {
                                    vertical: [],
                                    horizontal: []
                                  }
                                })
                                .reduce(
                                  (
                                    acc: { vertical: number[], horizontal: number[] },
                                    curr: { vertical: number[], horizontal: number[] }
                                  ) => {
                                    return {
                                      vertical: acc.vertical.concat(curr.vertical),
                                      horizontal: acc.horizontal.concat(curr.horizontal)
                                    }
                                  },
                                  {
                                    vertical: [],
                                    horizontal: []
                                  }
                                )
    newGuideLines.vertical.sort((a, b) => Math.abs(pointerPosition.x - a) - Math.abs(pointerPosition.x - b))
    newGuideLines.horizontal.sort((a, b) => Math.abs(pointerPosition.y - a) - Math.abs(pointerPosition.y - b))

    setGuideLines(newGuideLines)

    if (Math.abs(pointerPosition.x - newGuideLines.vertical[0]) < 5) {
      pointerPosition.x = newGuideLines.vertical[0]
    }

    if (Math.abs(pointerPosition.y - newGuideLines.horizontal[0]) < 5) {
      pointerPosition.y = newGuideLines.horizontal[0]
    }

    if (isAddingPoint && selectedShapeId) {
      const shape = shapes.find(s => s.id === selectedShapeId)

      if (!shape) {
        return
      }

      const addPointLine = createNewPointLine(
        shape,
        pointerPosition,
        forceEndRef.current
      )

      if (shapes[shapes.length - 1].type === AnnotationShapeType.NEW_POINT_LINE) {
        shapes.splice(
          shapes.length - 1,
          1,
          addPointLine
        )
      } else {
        shapes.push(addPointLine)
      }

      onShapesChange(shapes.concat())
      return
    }

    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }

    if (tool === AnnotationShapeType.FREEHAND || tool === AnnotationShapeType.ERASER) {
      const lastLine = shapes[shapes.length - 1];
      // add point
      lastLine.points = lastLine.points.concat([pointerPosition!.x, pointerPosition!.y]);

      // replace last
      shapes.splice(shapes.length - 1, 1, lastLine);
      onShapesChange && onShapesChange(shapes.concat());
    } else if (tool === AnnotationShapeType.ZONE) {

      let lastShape = shapes[shapes.length - 1];

      if (!lastShape || lastShape.type !== AnnotationShapeType.ZONE) {
        return
      }

      if (lastShape.points.length > 2) {
        lastShape.points = [lastShape.points[0], lastShape.points[1]]
      }

      const ellipsePoints = [
        pointerPosition!.x,
        pointerPosition!.y + 20,
        pointerPosition!.x,
        pointerPosition!.y - 20,
        pointerPosition!.x + 40,
        pointerPosition!.y,
        pointerPosition!.x - 40,
        pointerPosition!.y,
      ]

      lastShape.points = lastShape.points.concat(ellipsePoints);

      lastShape.color = {
        ...lastShape.color,
        a: 0.3
      }

      shapes.splice(
        shapes.length - 1,
        1,
        lastShape
      );

      onShapesChange && onShapesChange(shapes.concat());
    } else if (tool === AnnotationShapeType.CIRCLE) {

      const stage = event.target.getStage();
      const point = stage!.getPointerPosition();
      let lastShape = shapes[shapes.length - 1];

      if (!lastShape || lastShape.type !== AnnotationShapeType.CIRCLE) {
        return
      }

      if (lastShape.points.length > 2) {
        lastShape.points = [lastShape.points[0], lastShape.points[1]]
      }

      lastShape.points = lastShape.points.concat([
        point!.x, point!.y
      ]);

      shapes.splice(
        shapes.length - 1,
        1,
        lastShape
      );

      onShapesChange && onShapesChange(shapes.concat());
    }

  };

  const handleMouseUp = () => {
  };

  const handleLineChange = (shape: LayerShape) => (points: number[]) => {

    const newShape = shapes.find(s => s.id === shape.id)

    if (!newShape) {
      return
    }

    newShape.points = points
    onShapesChange && onShapesChange(shapes.concat());
  }

  const handleBlockAngleChange = (shape: LayerShape) => (blockAngle: number) => {
    const newShape = shapes.find(s => s.id === shape.id)

    if (!newShape) {
      return
    }

    newShape.blockAngle = blockAngle

    onShapesChange && onShapesChange(shapes.concat())
  }

  const handleTextChange = (shape: LayerShape) => (
    text?: string,
    fontFamily?: string,
    fontSize?: number,
    color?: RGBColor
  ) => {

    const newShape = shapes.find(s => s.id === shape.id)

    if (!newShape) {
      return
    }

    newShape.text = text
    newShape.fontFamily = fontFamily
    newShape.fontSize = fontSize
    newShape.color = color!

    onShapesChange && onShapesChange(shapes.concat())
  }

  const handleMouseLeave = (e: React.MouseEvent) => {

    if (stageContainerRef.current && isWithinBounds(
      e,
      stageContainerRef.current
    )) {
      return
    }

    setEraserPosition(null)
    setSelectedShapeId(null)
    setIsAddingPoint(false)
    setGuideLines({
      vertical: [],
      horizontal: []
    })
    forceEndRef.current = false

    onShapesChange(shapes.filter(s => s.type !== AnnotationShapeType.NEW_POINT_LINE))
  }

  const handleDelete = () => {
    onShapesChange(shapes.filter(s => s.id !== selectedShapeId))
    setSelectedShapeId(null)
  }

  const handleColorChange = (color: RGBColor) => {
    setColor(color)

    const newShape = shapes.find(s => s.id === selectedShapeId)

    if (!newShape) {
      return
    }

    newShape.color = color

    onShapesChange && onShapesChange(shapes.concat())
  }

  const handleAddPointToLine = () => {
    setIsAddingPoint(true)
    forceEndRef.current = false
  }

  const handleCancelAddPoint = useCallback(
    () => {
      setIsAddingPoint(false)
      forceEndRef.current = false
      onShapesChange(shapes.filter(s => s.type !== AnnotationShapeType.NEW_POINT_LINE))
    },
    [onShapesChange, shapes]
  )

  const handleTensionChange = (value: number) => {
    const shape = shapes.find(s => s.id === selectedShapeId)

    if (!shape) {
      return
    }

    shape.tension = value / 100

    onShapesChange && onShapesChange(shapes.concat())
  }

  const handleStrokeWeightChange = (value: number) => {
    const shape = shapes.find((s => s.id === selectedShapeId))

    if (!shape) {
      return
    }

    shape.strokeWidth = value

    onShapesChange && onShapesChange(shapes.concat())
  }

  const handleArrowSizeChange = (length: number, width: number) => {
    const shape = shapes.find((s => s.id === selectedShapeId))

    if (!shape) {
      return
    }

    shape.arrowLength = length
    shape.arrowWidth = width

    onShapesChange && onShapesChange(shapes.concat())
  }

  const selectedShape = shapes.find(s => s.id === selectedShapeId)

  const handleIsDashedChange = () => {
    const shape = shapes.find((s => s.id === selectedShapeId))

    if (!shape) {
      return
    }

    shape.isDashed = !shape.isDashed

    onShapesChange && onShapesChange(shapes.concat())
  }

  const handleKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isAddingPoint) {
        handleCancelAddPoint()
        e.preventDefault()
      }
    },
    [handleCancelAddPoint, isAddingPoint]
  )

  useEffect(
    () => {
      document.addEventListener(
        'keydown',
        handleKeydown
      )

      return () => {

        document.removeEventListener(
          'keydown',
          handleKeydown
        )
      }
    },
    [handleKeydown]
  )

  const handleFillColorChange = (e: CheckboxChangeEvent) => {
    const newShape = shapes.find(s => s.id === selectedShapeId)

    if (!newShape) {
      return
    }

    newShape.fillColor = e.target.checked ? {
      ...newShape.color,
      a: 0.3
    } : undefined

    onShapesChange && onShapesChange(shapes.concat())
  }

  const updateUserPreferences = async (userPreferences: UserPreferences) => {
    setUserPreferences(userPreferences)
  }

  const handleDefaultStrokeWeightChange = () => {
    if (userPreferences) {
      userPreferences.strokeWidth = selectedShape?.strokeWidth || 5

      updateUserPreferences(userPreferences)
    } else {
      const newUserPreferences = new UserPreferences({
        strokeWidth: selectedShape?.strokeWidth || 5,
        lineTension: 0,
        fontSize: selectedShape?.fontSize || 20,
        colorPalettes: {}
      })

      updateUserPreferences(newUserPreferences)
    }
  }

  const handleDefaultTensionChange = () => {
    if (userPreferences) {
      userPreferences.lineTension = selectedShape?.tension || 1

      updateUserPreferences(userPreferences)
    } else {
      const newUserPreferences = new UserPreferences({
        strokeWidth: 3,
        lineTension: selectedShape?.tension || 0,
        fontSize: selectedShape?.fontSize || 20,
        colorPalettes: {}
      })

      updateUserPreferences(newUserPreferences)
    }
  }

  const handleUserPreferencesChange = (userPreferences: UserPreferences) => {
    updateUserPreferences(userPreferences)
  }

  const handleRecentColorsChange = (colors: RGBColor[]) => {

    if (userPreferences) {
      userPreferences.colorPalettes.set("RECENT", new ColorPalette({colors: colors}))

      updateUserPreferences(userPreferences)
    } else {
      updateUserPreferences(new UserPreferences({
        strokeWidth: 3,
        lineTension: 0,
        fontSize: 20,
        colorPalettes: {"RECENT": {colors: colors}}
      }))
    }
  }

  const handleDefaultFontSizeChange = () => {

    if (userPreferences) {
      userPreferences.fontSize = selectedShape?.fontSize || 20

      updateUserPreferences(userPreferences)
    } else {
      const newUserPreferences = new UserPreferences({
        strokeWidth: 3,
        lineTension: 0,
        fontSize: selectedShape?.fontSize || 32,
        colorPalettes: {}
      })

      updateUserPreferences(newUserPreferences)
    }
  }

  return {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    shapes,
    stageRef,
    stageContainerRef,
    selectedShapeId,
    setSelectedShapeId,
    handleLineChange,
    handleMouseLeave,
    handleDelete,
    eraserPosition,
    handleBlockAngleChange,
    color,
    handleColorChange,
    handleAddPointToLine,
    handleCancelAddPoint,
    isAddingPoint,
    handleTensionChange,
    handleStrokeWeightChange,
    handleArrowSizeChange,
    selectedShape,
    handleIsDashedChange,
    guideLines,
    handleFillColorChange,
    handleTextChange,
    userPreferences,
    handleDefaultStrokeWeightChange,
    handleDefaultTensionChange,
    handleUserPreferencesChange,
    handleRecentColorsChange,
    handleDefaultFontSizeChange
  }
}

export default useWhiteboardLayerModel;
