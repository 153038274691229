import ClipDto from "../../services/clip/ClipDto";
import BucketObject from "../bucketobject/BucketObject";
import Annotation from "../annotation/Annotation";
import Tag from "../tag/Tag";

export default class Clip {
  id: string | null;
  creatorId: string | null
  sourceBucketObject: BucketObject | null
  startSecond: number | null
  endSecond: number | null
  annotations: Annotation[]
  title: string
  bucketObject: BucketObject | null
  tags: Tag[]

  constructor(dto: ClipDto) {
    this.id = dto.id;
    this.creatorId = dto.creatorId;
    this.sourceBucketObject = dto.sourceBucketObject ? new BucketObject(dto.sourceBucketObject) : null
    this.startSecond = dto.startSecond
    this.endSecond = dto.endSecond
    this.annotations = dto.annotations.map(a => new Annotation(a))
    this.title = dto.title
    this.bucketObject = dto.bucketObject ? new BucketObject(dto.bucketObject) : null
    this.tags = dto.tags.map(t => new Tag(t))
  }

  asDto() {
    return {
      id: this.id,
      creatorId: this.creatorId,
      sourceBucketObject: this.sourceBucketObject ? this.sourceBucketObject.asDto() : null,
      startSecond: this.startSecond,
      endSecond: this.endSecond,
      annotations: this.annotations.map(a => a.asDto()),
      title: this.title,
      bucketObject: this.bucketObject ? this.bucketObject.asDto() : null,
      tags: this.tags.map(t => t.asDto())
    } as ClipDto
  }
}

