import React from "react";
import ReactPlayer from "react-player";
import {message} from "antd";

interface Props {
  innerRef?: React.RefObject<ReactPlayer>;
  url?: string;
  playing?: boolean;
  onDuration?: (duration: number) => void;
  onProgress?: (state: {
    played: number
    playedSeconds: number
    loaded: number
    loadedSeconds: number
  }) => void
  onSeek?: (secs: number) => void;
  onReady?: () => void;
  onError?: () => void;
  volume: number;
  height?: number;
  width?: number;
  style?: React.CSSProperties
}

function VideoPlayer({
  innerRef,
  url,
  playing,
  onDuration,
  onProgress,
  onSeek,
  onReady,
  onError,
  volume,
  height,
  width,
  style
}: Props) {
  return (<>
      <ReactPlayer
        style={style || {
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        ref={innerRef}
        url={url}
        playing={playing}
        onReady={onReady}
        onDuration={onDuration}
        onError={(error: any) => {
          error?.message && message.error(error.toString())
          onError && onError()
        }}
        progressInterval={1000 / 60}
        onProgress={onProgress}
        onSeek={onSeek}
        height={height || 720}
        width={width || 1280}
        volume={volume}
      />
    </>
  )
}

export default VideoPlayer;
