import {useContext} from "react";
import {Button, Card, Form, Input, message, Space} from "antd";
import {UserContext} from "../../contexts/user/UserContext";
import {Link, useHistory, useLocation} from "react-router-dom";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import {useRegisterModel} from "./RegisterModel";
import RouteConstants from "../../util/RouteConstants";

export const Register: React.FC = () => {

  const {setUser} = useContext(UserContext);
  const history = useHistory();
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  let referredBy = params.get("referredBy")


  if (referredBy) {
    localStorage.setItem("referredBy", referredBy)
  } else {
    referredBy = localStorage.getItem("referredBy")
  }

  const model = useRegisterModel(setUser);

  const onFinish = async (values: any) => {
    const {
      email,
      password,
      confirmPassword,
    } = values;

    try {
      if (await model.register(email, password, confirmPassword, referredBy)) {
        history.push(RouteConstants.CONFIRM_EMAIL)
      }
    } catch (err: any) {
      message.error(err.message);
    }
  };

  return (
    <Card style={{marginTop: 32}}>
      <Form
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        style={{minWidth: '400px'}}
      >
        <Form.Item
          name="email"
          rules={[{required: true, message: 'Please input your Email!'}, {type: 'email'}]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon"/>}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{required: true, message: 'Please input your Password!'}]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon"/>}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({getFieldValue}) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('The two passwords that you entered do not match!');
              },
            }),
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon"/>}
            type="password"
            placeholder="Confirm Password"
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" className="register-form-button">
              Register
            </Button>
            or <Link to={RouteConstants.LOGIN}>Login</Link>
          </Space>

        </Form.Item>
      </Form>
    </Card>
  );
};
