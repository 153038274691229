import BucketObject from "../bucketobject/BucketObject";
import AnnotationDto from "../../services/annotation/AnnotationDto";
import AnnotationShape from "./AnnotationShape";

export default class Annotation {

  id: string;
  startSecond: number;
  endSecond: number;
  overlayImage: BucketObject | null;
  isPausedDuring: boolean;
  shapes: AnnotationShape[]

  constructor(dto: AnnotationDto) {
    this.id = dto.id;
    this.startSecond = dto.startSecond;
    this.endSecond = dto.endSecond;
    this.overlayImage = dto.overlayImage ? new BucketObject(dto.overlayImage) : null
    this.isPausedDuring = dto.isPausedDuring;
    this.shapes = dto.shapes.map(s => new AnnotationShape(s))
  }

  timeRangeContains(time: number) {
    return time <= this.endSecond && time >= this.startSecond
  }

  asDto() {
    return {
      id: this.id,
      startSecond: this.startSecond,
      endSecond: this.endSecond,
      overlayImage: this.overlayImage ? this.overlayImage.asDto() : null,
      isPausedDuring: this.isPausedDuring,
      shapes: this.shapes
    } as AnnotationDto
  }
}
