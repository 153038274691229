import UserDto from "../../services/user/UserDto";
import Subscription from "../subscription/Subscription";

export default class User {

  id: string;
  username: string;
  email: string;
  isEmailConfirmed: boolean;
  subscriptions: Subscription[]

  constructor(dto: UserDto) {
    this.id = dto.id;
    this.username = dto.username;
    this.email = dto.email;
    this.isEmailConfirmed = dto.isEmailConfirmed
    this.subscriptions = dto.subscriptions ? dto.subscriptions.map(s => new Subscription(s)) : []
  }

  asDto() {
    return {
      id: this.id,
      username: this.username,
      isEmailConfirmed: this.isEmailConfirmed
    } as UserDto
  }
}
