import React, {PropsWithChildren} from "react";
import TimelineFrame from "./TimelineFrame";
import {Button, Popover, Tooltip} from "antd";
import Annotation from "../../../types/annotation/Annotation";
import {DeleteOutlined} from "@ant-design/icons";
import GrabVerticalOutlined from "../../icons/GrabVerticalOutlined/GrabVerticalOutlined";
import GrabHorizontalOutlined from "../../icons/GrabHorizontalOutlined/GrabHorizontalOutlined";

export interface Segment {
  start: number
  end: number
  playerStart: number
  playerEnd: number
  layer?: Annotation
}

interface Props {
  segment: Segment
  url: string
  onMouseEnterExitDraggable: (elementId: string | null, handle?: 'start' | 'end') => void;
  onDelete?: () => void
}

function TimelineSegment({
  segment,
  url,
  onMouseEnterExitDraggable,
  onDelete,
}: PropsWithChildren<Props>) {

  const numFrames = Math.floor((segment.end - segment.start) / 5) + 1

  const frames = numFrames > 0 ? Array(numFrames)
    .fill(
      0,
      0,
      numFrames
    )
    .map((x, i) => {
      return (<TimelineFrame
        url={url}
        startTime={segment.playerStart + 5 * i * (!!segment.layer ? 0 : 1)}
        layer={segment.layer}
      />)
    }) : []

  return (<div style={{display: 'flex'}}>


    <div
      style={{
        width: 0,
        height: 0
      }}
    >
      {segment.layer && <Button
          className="hoverable draggable"
          style={{
            position: 'relative',
            top: -16,
            right: 0,
            zIndex: 10,
            transition: "none",
            borderRadius: '2px 2px 0 0',
            height: 16,
            width: 24
          }}
          icon={<GrabHorizontalOutlined
            style={{
              fontSize: 28,
              height: '100%',
              display: 'flex',
              alignItems: "center",
              justifyContent: 'center',
              width: '100%'
            }}
          />}
          type="primary"
          onMouseEnter={() => segment.layer && onMouseEnterExitDraggable(
            segment.layer.id,
            'start'
          )}
          onMouseLeave={() => segment.layer && onMouseEnterExitDraggable(null)}
      />}
    </div>
    <Popover
      placement="top"
      content={<Tooltip title="Remove Annotation">
        <Button
          type="text"
          onClick={onDelete}
          icon={<DeleteOutlined/>}
          danger
        />
      </Tooltip>}
      overlayStyle={{...(!segment.layer ? {display: 'none'} : {})}}
    >
      <div
        style={{
          display: 'flex', ...(segment.layer ? {
            borderLeft: '2px solid #28AC8D',
            borderRight: '2px solid #28AC8D',
          } : {})
        }}
      >
        <div
          style={{
            display: "flex",
            width: 'fit-content'
          }}
        >

          <div
            style={{
              display: 'flex',
              width: (segment.end - segment.start) / 5 * 320 + 'px',
              height: '100%',
              overflow: 'hidden'
            }}
          >
            {frames}
          </div>
        </div>
      </div>
    </Popover>

    <div
      style={{
        width: 0,
        height: 0
      }}
    >
      {segment.layer && <Button
          className="hoverable draggable"
          style={{
            position: 'relative',
            top: -16,
            right: 24,
            zIndex: 10,
            transition: "none",
            borderRadius: '2px 2px 0 0',
            height: 16,
            width: 24
          }}
          icon={<GrabVerticalOutlined
            style={{
              fontSize: 28,
              height: '100%',
              display: 'flex',
              alignItems: "center",
              justifyContent: 'center',
              width: '100%'
            }}
            rotate={90}
          />}
          type="primary"
          onMouseEnter={() => segment.layer && onMouseEnterExitDraggable(
            segment.layer.id,
            'end'
          )}
          onMouseLeave={() => segment.layer && onMouseEnterExitDraggable(null)}
      />}
    </div>
  </div>)
}

export default TimelineSegment;
