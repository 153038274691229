import {useContext} from "react";
import {Button, Card, Checkbox, Form, Input, message, Space} from "antd";
import {Link, useHistory} from "react-router-dom";
import {UserContext} from "../../contexts/user/UserContext";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import {useLoginModel} from "./LoginModel";
import RouteConstants from "../../util/RouteConstants";

export const Login: React.FC = () => {

  const {setUser} = useContext(UserContext);
  const history = useHistory();

  const model = useLoginModel(setUser);

  const onFinish = async (values: any) => {

    const {email, password} = values;

    try {
      if (await model.login(email, password)){
        history.push(RouteConstants.CLIPS)
      }
    } catch (err: any) {
      message.error(err.message);
    }
  };

  return (
    <Card style={{marginTop: 32}}>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{remember: true}}
        onFinish={onFinish}
        style={{minWidth: '400px'}}
      >
        <Form.Item
          name="email"
          rules={[{required: true, message: 'Please input your Email!'}]}
        >
          <Input prefix={<MailOutlined className="site-form-item-icon"/>} placeholder="Email"/>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{required: true, message: 'Please input your Password!'}]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon"/>}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          {/*<a className="login-form-forgot" href="">*/}
          {/*  Forgot password*/}
          {/*</a>*/}
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
            or <Link to={RouteConstants.REGISTER}>Register</Link>
          </Space>
        </Form.Item>
        <Form.Item>
          <Link to={RouteConstants.RESET_PASSWORD}>Forgot Password?</Link>
        </Form.Item>
      </Form>
    </Card>
  )
};
