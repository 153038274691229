import {useEffect, useState} from "react";
import Clip from "../../types/clip/Clip";
import ClipService from "../../services/clip/ClipService";
import {useHistory} from "react-router-dom";
import RouteConstants from "../../util/RouteConstants";

const useClipsModel = () => {

  const [clips, setClips] = useState<Clip[]>([])
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [selectedClipIds, setSelectedClipIds] = useState<string[]>([])
  const history = useHistory()
  const [activeView, setActiveView] = useState<'grid' | 'table'>('grid')
  const [searchText, setSearchText] = useState<string>()
  const [selectedTags, setSelectedTags] = useState<string[]>()

  const fetchClips = async () => {
    const newClips = await ClipService.readClips()

    if (newClips) {
      setClips(newClips.map(c => new Clip(c)))
    }
  }

  useEffect(() => {
    fetchClips()
  }, [])

  const handleCreateClip = async () => {
    const createdClip = await ClipService.createClip(new Clip({
      annotations: [],
      endSecond: null,
      sourceBucketObject: null,
      startSecond: null,
      title: "",
      id: null,
      creatorId: null,
      bucketObject: null,
      tags: []
    }).asDto())

    if (createdClip && createdClip.id) {
      history.push(RouteConstants.CLIP.replace(':clipId', createdClip.id))
    }
  }

  const handleClickClipCard = (id: string) => () => {
    if (!isEditing) {
      history.push(RouteConstants.CLIP.replace(
        ':clipId',
        id
      ))
    } else {
      if (!selectedClipIds.find(c => c === id)) {
        setSelectedClipIds(selectedClipIds.concat(id))
      } else {
        setSelectedClipIds(selectedClipIds.filter(c => c !== id)
                                          .concat())
      }
    }
  }

  const handleClickEdit = () => {
    setIsEditing(true)
  }

  const handleClickCancel = () => {
    setIsEditing(false)
    setSelectedClipIds([])
  }

  const handleClickDelete = async () => {

    if (selectedClipIds.length < 1) {
      return
    }

    const successful = await ClipService.deleteClips(selectedClipIds)

    if (successful) {
      fetchClips()
      handleClickCancel()
    }
  }

  const handleActiveViewChange = (value: 'grid' | 'table') => {
    setActiveView(value)
  }

  const handleFormValuesChange = (values: any) => {
    setSearchText(values.search)
    setSelectedTags(values.tags)
  }

  const filteredClips = clips
    .filter(c => c.title.includes(searchText || ''))
    .filter(c => selectedTags && selectedTags.length > 0 ? selectedTags?.map(t => !!c.tags.find(ct => ct.name === t)).every(v => v) : true)

  return {
    clips: filteredClips,
    handleCreateClip,
    handleClickClipCard,
    handleClickEdit,
    handleClickDelete,
    handleClickCancel,
    isEditing,
    selectedClipIds,
    activeView,
    handleActiveViewChange,
    handleFormValuesChange
  }
}

export default useClipsModel;
