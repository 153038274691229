import {
  CaretRightOutlined,
  ClearOutlined,
  ExportOutlined,
  FontSizeOutlined,
  PauseOutlined,
  RedoOutlined,
  SoundOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
  UndoOutlined,
  UploadOutlined
} from "@ant-design/icons";
import {Button, Card, Popover, Progress, Radio, Slider, Space, Tooltip, Typography, Upload} from "antd";
import React, {MutableRefObject, PropsWithChildren, useCallback, useEffect, useRef, useState} from "react";
import VideoPlayer from "./VideoPlayer";
import ReactPlayer from "react-player";
import {UploadFile} from "antd/lib/upload/interface";
import useVolume from "../../hooks/useVolume";
import useWhiteBoardModel from "./whiteboard/WhiteboardModel";
import WhiteboardLayer from "./whiteboard/layer/WhiteboardLayer";
import MediaSlider from "./mediaslider/MediaSlider";
import {Segment} from "./mediaslider/TimelineSegment";
import Annotation from "../../types/annotation/Annotation";
import {AnnotationShapeType} from "../../types/annotation/AnnotationShape";
import useClipEditHistory from "../../views/clip/useClipEditHistory";
import ZoneOutlined from "../icons/ZoneOutlined/ZoneOutlined";
import BlockOutlined from "../icons/BlockOutlined/BlockOutlined";
import ArrowSolidOutlined from "../icons/ArrowSolidOutlined/ArrowSolidOutlined";
import CircleOutlined from "../icons/CircleOutlined/CircleOutlined";
import LineVerticalOutlined from "../icons/LineVerticalOutlined/LineVerticalOutlined";

const {Text} = Typography;

interface Props {
  url?: string;
  onClickUpload?: (file: UploadFile) => void;
  onClickNext?: () => void;
  onClickPrevious?: () => void;
  hasTrimRange?: boolean;
  trimRange: number[] | null
  onTrimRangeChange: (start: number, end: number) => void;
  getAnnotationFilePathRef?: MutableRefObject<(((id: string) => string) | undefined)>;
  onClickCreateClip?: () => void;
  isUploading?: boolean
  annotations: Annotation[]
  onAnnotationsChange: (annotations: Annotation[]) => void
  isLoadingClip: boolean
  uploadPercent: number
}

const secondsToTime = (seconds: number) => {
  return `${Math.floor(seconds / 60)}:${Math.floor(seconds % 60)
                                            .toString()
                                            .padStart(2, '0')}`;
}

export const calculateElapsedPauseSeconds = (time: number, annotations: Annotation[]) => {
  return annotations
    .map(layer => {
      if (layer.startSecond > time) {
        return 0;
      }

      if (layer.endSecond > time) {
        return time - layer.startSecond;
      }

      return layer.endSecond - layer.startSecond;
    })
    .reduce((curr, acc) => curr + acc, 0)
}

function FilmVideoPlayer({
  url,
  onClickUpload,
  onClickNext,
  onClickPrevious,
  getAnnotationFilePathRef,
  onClickCreateClip,
  isUploading,
  annotations,
  onAnnotationsChange,
  trimRange,
  onTrimRangeChange,
  isLoadingClip,
  uploadPercent
}: PropsWithChildren<Props>) {

  const [currentPlayerTime, setCurrentPlayerTime] = useState<number>(0);
  const [videoLength, setVideoLength] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [isPlayerPlaying, setIsPlayerPlaying] = useState<boolean>(true);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const {
    handleUndo,
    handleRedo,
    handleTrimRangeChange,
    handleAnnotationsChange
  } = useClipEditHistory(
    trimRange,
    annotations,
    onTrimRangeChange,
    onAnnotationsChange
  )

  const {
    tool,
    handleShapesChange,
    handleToolChange,
    handleTimeRangeChange,
    stageMapRef,
    handleAddAnnotation,
    handleDeleteAnnotation,
    handleClickClear,
  } = useWhiteBoardModel(
    currentPlayerTime,
    annotations,
    handleAnnotationsChange,
    getAnnotationFilePathRef
  )

  const {
    volume,
    handleVolumeSliderChange,
    handleMuteClicked,
  } = useVolume();

  const videoPlayerRef = useRef<ReactPlayer>(null);

  const handleDuration = (duration: number) => {
    setVideoLength(duration)

    const combinedAnnotationsLength = (annotations
      .map(layer => layer.endSecond - layer.startSecond)
      .reduce(
        (curr, acc) => curr + acc,
        0
      ))

    if (!trimRange) {
      onTrimRangeChange(
        0,
        duration + combinedAnnotationsLength
      )
      return
    }

    if (trimRange[1] > duration + combinedAnnotationsLength) {
      onTrimRangeChange(
        trimRange[0],
        duration + combinedAnnotationsLength
      )
    }
  }

  const handleProgress = (state: {
    playedSeconds: number,
  },) => {

    const elapsedPauseSeconds = calculateElapsedPauseSeconds(currentPlayerTime, annotations)

    if (trimRange) {
      if (state.playedSeconds + elapsedPauseSeconds > trimRange[1]) {

        videoPlayerRef.current?.seekTo(trimRange[0] - calculateElapsedPauseSeconds(trimRange[0], annotations),
          'seconds'
        );
        setCurrentPlayerTime(trimRange[0])
        return;
      }
    }

    const isInPauseRange = annotations
      .map(layer => {
        return state.playedSeconds + elapsedPauseSeconds > layer.startSecond && state.playedSeconds + elapsedPauseSeconds < layer.endSecond;
      })
      .reduce((curr, acc) => curr || acc, false);

    if (isInPauseRange && isPlaying) {
      setIsPlayerPlaying(false)
    }
    setCurrentPlayerTime(state.playedSeconds + elapsedPauseSeconds)
  }

  const handleSliderChange = (newValue: number) => {

    const newValueSecs = newValue / 1000;

    const elapsedPauseSeconds = annotations
      .map(layer => {
        if (layer.startSecond > newValueSecs) {
          return 0;
        }

        if (layer.endSecond > newValueSecs) {
          return newValueSecs - layer.startSecond;
        }

        return layer.endSecond - layer.startSecond;
      })
      .reduce((curr, acc) => curr + acc, 0)

    videoPlayerRef.current?.seekTo(newValueSecs - elapsedPauseSeconds, 'seconds');
    setCurrentPlayerTime(newValueSecs)
  }

  const handleClickPlayOrPause = () => {
    setIsPlaying(!isPlaying);
  }

  useEffect(() => {

    let interval: NodeJS.Timeout;

    interval = setInterval(() => {

      const isInPauseRange = annotations
        .map(layer => {
          return currentPlayerTime > layer.startSecond && currentPlayerTime < layer.endSecond;
        })
        .reduce((curr, acc) => curr || acc, false);

      if (isPlaying && isInPauseRange) {
        setCurrentPlayerTime(currentPlayerTime + 1 / 60)
      } else if (isPlaying && !isPlayerPlaying) {
        setIsPlayerPlaying(true)
      }
    }, 1000 / 60)

    return () => clearInterval(interval)
  }, [isPlaying, currentPlayerTime, annotations, isPlayerPlaying])

  useEffect(() => {
    if (!isPlaying) {
      setIsPlayerPlaying(false)
    }
  }, [isPlaying])

  const annotationSegments = annotations
    .sort((a, b) => a.startSecond - b.startSecond)
    .map(layer => {
      return {
        start: layer.startSecond,
        end: layer.endSecond,
        playerStart: layer.startSecond - calculateElapsedPauseSeconds(layer.startSecond, annotations),
        playerEnd: layer.endSecond - calculateElapsedPauseSeconds(layer.endSecond, annotations),
        layer: layer
      } as Segment
    })

  const middleSegments: Segment[] = [];

  for (let i = 0; i < annotationSegments.length - 1; i++) {
    middleSegments.push({
      start: annotationSegments[i].end,
      end: annotationSegments[i + 1].start,
      playerStart: annotationSegments[i].end - calculateElapsedPauseSeconds(annotationSegments[i].end, annotations),
      playerEnd: annotationSegments[i + 1].start - calculateElapsedPauseSeconds(annotationSegments[i + 1].start,
        annotations
      ),
    })
  }

  const segments: Segment[] = [
    {
      start: 0,
      end: annotations && annotations.length > 0 ? annotations.sort((a,
        b
      ) => a.startSecond - b.startSecond)[0].startSecond : videoLength,
      playerStart: 0,
      playerEnd: annotations && annotations.length > 0 ? annotations.sort((a,
        b
      ) => a.startSecond - b.startSecond)[0].startSecond : videoLength
    }, ...annotationSegments
      .concat(middleSegments)
      .sort((a, b) => b.start - a.start), ...(annotations.length > 0 ? [
      {
        start: annotations.sort((a, b) => a.startSecond - b.startSecond)[annotations.length - 1].endSecond,
        end: videoLength + (annotations
          .map(layer => layer.endSecond - layer.startSecond)
          .reduce((curr, acc) => curr + acc, 0)),
        playerStart: annotations.sort((a, b) => a.startSecond - b.startSecond)[annotations.length - 1].endSecond -
          calculateElapsedPauseSeconds(
            annotations.sort((a, b) => b.startSecond - a.startSecond)[annotations.length - 1].endSecond,
            annotations
          ),
        playerEnd: videoLength
      }
    ] : [])
  ].sort((a, b) => a.start - b.start)

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const commandKey = navigator.platform.toUpperCase()
                                  .startsWith("MAC") ? e.metaKey : e.ctrlKey
      if (commandKey && !e.shiftKey && e.key === 'z') {
        handleUndo()
      } else if (commandKey && e.shiftKey && e.key === 'z') {
        handleRedo()
      }
    },
    [handleRedo, handleUndo]
  )

  useEffect(
    () => {

      document.addEventListener(
        'keydown',
        handleKeyDown
      )

      return () => {
        document.removeEventListener(
          'keydown',
          handleKeyDown
        )
      }
    },
    [handleKeyDown]
  )

  return (<div
    style={{
      display: 'flex',
      flexDirection: 'column'
    }}
  >
    <Space>
      <div>
        <Space
          direction="vertical"
          style={{alignItems: 'center'}}
        >
          <Radio.Group
            className="ant-radio-group-vertical"
            disabled={!annotations
              .map(l => l.timeRangeContains(currentPlayerTime))
              .reduce(
                (curr, acc) => {
                  return curr || acc
                },
                false
              )}
            value={tool}
            onChange={handleToolChange}
          >
            <Radio.Button value={AnnotationShapeType.LINE}>
              <LineVerticalOutlined/>
            </Radio.Button>
            <Radio.Button value={AnnotationShapeType.BLOCK}>
              <BlockOutlined style={{fontSize: 16}}/>
            </Radio.Button>
            <Radio.Button value={AnnotationShapeType.ARROW}>
              <ArrowSolidOutlined style={{fontSize: 16}}/>
            </Radio.Button>
            <Radio.Button value={AnnotationShapeType.ZONE}>
              <ZoneOutlined style={{fontSize: 16}}/>
            </Radio.Button>
            <Radio.Button value={AnnotationShapeType.CIRCLE}>
              <CircleOutlined style={{fontSize: 16}}/>
            </Radio.Button>
            <Radio.Button value={AnnotationShapeType.TEXT}>
              <FontSizeOutlined style={{fontSize: 16}}/>
            </Radio.Button>
          </Radio.Group>
          <Button
            danger
            title="Clear"
            onClick={handleClickClear}
            icon={<ClearOutlined/>}
          />
          <Space direction="vertical">
            <Button
              title={`Undo (${navigator.platform.toUpperCase()
                                       .startsWith("MAC") ? 'Cmd' : "Ctrl"} + Z)`}
              icon={<UndoOutlined/>}
              onClick={handleUndo}
            />
            <Button
              title={`Redo (${navigator.platform.toUpperCase()
                                       .startsWith("MAC") ? 'Cmd' : "Ctrl"} + Shift + Z)`}
              icon={<RedoOutlined/>}
              onClick={handleRedo}
            />
          </Space>
        </Space>
      </div>
      <div
        className="film-video-player"
        style={{
          position: 'relative',
          height: '720px',
          width: '1280px',
          background: 'white',
        }}
      >
        {!isUploading && <VideoPlayer
            innerRef={videoPlayerRef}
            playing={isPlayerPlaying && !isDragging}
            onDuration={handleDuration}
            onProgress={handleProgress}
            onReady={() => setIsReady(true)}
            onError={() => setIsReady(false)}
            url={url}
            volume={volume / 100}
        />}
        {annotations.map(layer => {
          return <WhiteboardLayer
            layerId={layer.id}
            shapes={layer.shapes}
            onShapesChange={handleShapesChange(layer.id)}
            tool={tool}
            visible={currentPlayerTime > layer.startSecond && currentPlayerTime < layer.endSecond}
            stageMapRef={stageMapRef}
            editable
          />
        })}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              zIndex: 15,
              margin: '10px'
            }}
          >
            <div/>
            <div>
              {onClickCreateClip && <Tooltip title="Create clip from this play">
                  <Button
                      shape="circle"
                      icon={<ExportOutlined/>}
                      onClick={onClickCreateClip}
                      size="large"
                  />
              </Tooltip>}
            </div>
          </div>
          <div/>
          <div
            style={{
              display: 'flex',
              zIndex: 15,
              justifyContent: 'flex-end',
              margin: '80px 40px'
            }}
          >
            <div/>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div/>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {onClickUpload && !isReady && !isLoadingClip && !url && <Upload
                  beforeUpload={(file: UploadFile) => {
                    onClickUpload(file);
                    return false;
                  }}
                  maxCount={1}
                  showUploadList={false}
              >
                  <Button
                      icon={<UploadOutlined/>}
                      loading={isUploading}
                  >{isUploading ? 'Uploading' : 'Click to upload'}</Button>
              </Upload>}
              {!!uploadPercent && <Progress
                  percent={uploadPercent}
                  strokeColor={'#48b89a'}
                  showInfo={false}
              />}
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',

            }}
          >
            <div
              style={{
                height: '30px',
                margin: '0 10px',
                position: 'relative'
              }}
            >
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: 'white',
                marginLeft: 8,
                marginRight: 8,
                marginBottom: 8,
              }}
            >
              <div>
                <Text style={{color: 'white'}}> {secondsToTime(currentPlayerTime)} / {secondsToTime((videoLength) +
                  (annotations
                    .map(layer => layer.endSecond - layer.startSecond)
                    .reduce(
                      (curr, acc) => curr + acc,
                      0
                    )))}</Text>
              </div>
              <div>
                <Space>
                  {onClickPrevious && <Button
                      type="text"
                      onClick={onClickPrevious}
                      icon={<StepBackwardOutlined
                        style={{
                          fontSize: 24,
                          color: 'white'
                        }}
                      />}
                  />}
                  {isPlaying ? <Button
                    type="text"
                    onClick={handleClickPlayOrPause}
                    style={{zIndex: 15}}
                    icon={<PauseOutlined
                      style={{
                        fontSize: 24,
                        color: 'white'
                      }}
                    />}
                  /> : <Button
                    type="text"
                    onClick={handleClickPlayOrPause}
                    style={{zIndex: 15}}
                    icon={<CaretRightOutlined
                      style={{
                        fontSize: 24,
                        color: 'white'
                      }}
                    />}
                  />}
                  {onClickNext && <Button
                      type="text"
                      onClick={onClickNext}
                      icon={<StepForwardOutlined
                        style={{
                          fontSize: 24,
                          color: 'white'
                        }}
                      />}
                  />}
                </Space>
              </div>
              <div>
                <Space>
                  <Popover
                    placement="top"
                    content={<Slider
                      defaultValue={100}
                      value={volume}
                      vertical
                      style={{
                        height: 100
                      }}
                      onChange={handleVolumeSliderChange}
                    />}
                  >
                    <Button
                      type="text"
                      onClick={handleMuteClicked}
                      style={{zIndex: 10}}
                      icon={<SoundOutlined
                        style={{
                          fontSize: 24,
                          marginRight: 4,
                          color: 'white'
                        }}
                      />}
                    />
                  </Popover>
                </Space>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Space>
    <Card
      type="inner"
      style={{
        width: '100%',
      }}
      bodyStyle={{
        height: 'fit-content',
      }}

    >
      <MediaSlider
        value={currentPlayerTime * 1000}
        min={0}
        max={videoLength * 1000 + (annotations
          .map(layer => layer.endSecond - layer.startSecond)
          .reduce((curr, acc) => curr + acc, 0)) * 1000}
        onClick={handleSliderChange}
        segments={segments}
        url={url || ''}
        trimRange={trimRange}
        onAnnotationTimeRangeChange={handleTimeRangeChange}
        onTrimRangeChange={handleTrimRangeChange}
        onAddAnnotation={handleAddAnnotation}
        onDeleteAnnotation={handleDeleteAnnotation}
        setIsDragging={setIsDragging}
      />
    </Card>
  </div>)
}

export default FilmVideoPlayer;
