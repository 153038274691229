import {useHistory, useParams} from "react-router-dom";
import {useCallback, useEffect, useRef, useState} from "react";
import S3Service from "../../services/s3service/S3Service";
import ClipService from "../../services/clip/ClipService";
import {message} from "antd";
import Clip from "../../types/clip/Clip";
import {UploadFile} from "antd/lib/upload/interface";
import Annotation from "../../types/annotation/Annotation";
import {AnnotationShapeType} from "../../types/annotation/AnnotationShape";
import useBucketObject from "../../hooks/useBucketObject";
import RouteConstants from "../../util/RouteConstants";
import Tag from "../../types/tag/Tag";
import {useDebouncedCallback} from "use-debounce";

const dataURItoBlob = (dataURI: string) => {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

const useClipEditorModel = () => {

  const {clipId} = useParams<{ clipId: string }>()

  const history = useHistory();

  const [clip, setClip] = useState<Clip>()
  const [isUploading, setIsUploading] = useState(false)
  const [isRendering, setIsRendering] = useState(false)
  const [uploadPercent, setUploadPercent] = useState<number>(0)
  const {
    blobFilepath,
    isLoadingClip
  } = useBucketObject(
    clip?.sourceBucketObject?.getLink || '',
    isUploading
  )

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const getAnnotationFilePathRef = useRef<(id: string) => string>()

  const fetchClip = useCallback(async () => {
    const result = await ClipService.readClip(clipId)

    if (result) {
      setClip(new Clip(result))
    }
  }, [setClip, clipId])

  useEffect(() => {
    fetchClip()
  }, [clipId, fetchClip])

  const handleTrimRangeChange = (start: number, end: number) => {

    if (clip) {
      clip.startSecond = start
      clip.endSecond = end
      setClip(new Clip(clip.asDto()))
    }
  }

  const updateClip = useCallback(async () => {
    if (clip) {

      clip.annotations.forEach(a => a.shapes = a.shapes.filter(s => s.type !== AnnotationShapeType.NEW_POINT_LINE))

      try {

        const updatedClip = await ClipService.updateClip(clip.asDto())

        if (updatedClip) {
          message.success('Changes saved')
        }

      } catch (err: any) {
        message.error(err.message)
      }

    }
  }, [clip])

  const debouncedUpdateClip = useDebouncedCallback(
    updateClip,
    5000
  )

  useEffect(() => {
    debouncedUpdateClip()
  }, [clip, debouncedUpdateClip])

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newClip = clip;

    if (newClip) {
      newClip.title = event.target.value
      setClip(new Clip(newClip.asDto()))
    }
  }

  const handleUploadProgress = (progressEvent: ProgressEvent) => {
    setUploadPercent(progressEvent.loaded / progressEvent.total * 100)
  }

  const handleUpload = async (file: UploadFile) => {
    const blob = file as unknown as Blob;
    if (clip && clip.sourceBucketObject) {

      setIsUploading(true)

      await S3Service.putBucketObject(
        clip.sourceBucketObject.updateLink,
        blob,
        'video/mp4',
        handleUploadProgress
      )

      setIsUploading(false)
    }
  }

  const handleDownload = async () => {

    if (clip) {
      clip.annotations.forEach(a => {
        const filePath = getAnnotationFilePathRef.current && getAnnotationFilePathRef.current(a.id)

        if (a.overlayImage && filePath) {
          S3Service.putBucketObject(a.overlayImage.updateLink, dataURItoBlob(filePath), 'image/png')
        }
      })
    }

  }

  const handleAnnotationsChange = (annotations: Annotation[]) => {

    if (!clip) {
      return
    }

    clip.annotations = annotations
    setClip(new Clip(clip.asDto()))
  }

  const handleClickDownload = async () => {

    if (!clip || !clip.id) {
      return
    }
    setIsRendering(true)
    await updateClip()

    await Promise.all(clip.annotations.map(async (a) => {
      const filepath = getAnnotationFilePathRef.current && getAnnotationFilePathRef.current(a.id)

      if (a && a.overlayImage && filepath) {
        return await S3Service.putBucketObject(a.overlayImage.updateLink, dataURItoBlob(filepath), 'image/png')
      }
    }))

    const clipWithLink = await ClipService.readClipWithDownloadLink(clip.id)

    if (clipWithLink?.bucketObject?.getLink) {
      const blob = await S3Service.getBucketObject(clipWithLink.bucketObject?.getLink)

      if (blob) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = clip.title;
        link.click();
      }
    }

    setIsRendering(false)
  }

  const handleClickDelete = () => {
    setIsDeleteModalVisible(true)
  }

  const handleDelete = async () => {

    setIsDeleting(true)

    if (!clip?.id) {
      return
    }

    const isSuccessful = await ClipService.deleteClip(clip.id)

    if (isSuccessful) {
      history.push(RouteConstants.CLIPS)
    } else {
      setIsDeleting(false)
      message.error("Delete Failed")
    }
  }

  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false)
  }

  const handleTagsChange = (tags: Tag[]) => {
    if (!clip) {
      return
    }

    clip.tags = tags
    setClip(new Clip(clip.asDto()))
  }

  return {
    clip,
    handleTrimRangeChange,
    handleTitleChange,
    handleUpload,
    isUploading,
    handleDownload,
    getAnnotationFilePathRef,
    handleAnnotationsChange,
    handleClickDownload,
    isRendering,
    url: blobFilepath,
    isLoadingClip,
    uploadPercent,
    isDeleteModalVisible,
    handleClickDelete,
    handleDelete,
    handleCancelDelete,
    isDeleting,
    handleTagsChange
  }

}

export default useClipEditorModel;
