import {MutableRefObject, RefObject, useRef, useState} from "react";
import {RadioChangeEvent} from "antd";
import Konva from "konva";
import {RGBColor} from "react-color";
import AnnotationShape, {AnnotationShapeType} from "../../../types/annotation/AnnotationShape";
import Annotation from "../../../types/annotation/Annotation";
import AnnotationService from "../../../services/annotation/AnnotationService";

export type LayerShape = {
  id: string
  type: AnnotationShapeType
  points: number[]
  color: RGBColor
  blockAngle?: number
};

const useWhiteBoardModel = (
  currentTime: number,
  annotations: Annotation[],
  onAnnotationsChange: (annotations: Annotation[]) => void,
  getAnnotationFilePathRef?: MutableRefObject<(((id: string) => string | undefined) | undefined)>
) => {

  const [tool, setTool] = useState<AnnotationShapeType>(AnnotationShapeType.LINE);
  const stageMapRef = useRef<Map<string, RefObject<Konva.Stage>>>(new Map())

  const getAnnotationFilePath = (id: string) => {
    return stageMapRef.current.get(id)
                      ?.current
                      ?.getStage()
                      .toDataURL()
  }

  if (getAnnotationFilePathRef) {
    getAnnotationFilePathRef.current = getAnnotationFilePath
  }

  const handleShapesChange = (id: string) => (shapes: AnnotationShape[]) => {
    const annotation = annotations.find(l => l.id === id);

    if (annotation) {
      annotation.shapes = shapes

      onAnnotationsChange(annotations.concat())
    }
  }

  const handleToolChange = (event: RadioChangeEvent) => {
    setTool(event.target.value)
  }

  const handleTimeRangeChange = (id: string) => (values: number[]) => {

    const annotation = annotations.find(l => l.id === id);
    if (annotation) {
      annotation.startSecond = values[0] / 1000;
      annotation.endSecond = values[1] / 1000;

      onAnnotationsChange(annotations.concat())
    }
  }

  const handleDragLayerHandle = (layerId: string, handle: 'start' | 'end', value: number) => {
    const annotation = annotations.find(l => l.id === layerId);
    if (!annotation) {
      return
    }

    if (handle === 'start') {
      annotation.endSecond = annotation.endSecond + value / 1000 - annotation.startSecond
      onAnnotationsChange(annotations.concat())
    }

    if (handle === 'end') {
      annotation.endSecond = value / 1000
      onAnnotationsChange(annotations.concat())
    }
  }

  const handleAddAnnotation = async (time: number) => {
    const annotation = await AnnotationService.createAnnotation({
      id: '',
      startSecond: time,
      endSecond: time + 1,
      isPausedDuring: true,
      overlayImage: null,
      shapes: []
    })

    if (!annotation) {
      return
    }

    if (annotations.map(a => a.timeRangeContains(time))
                   .reduce(
                     (curr, acc) => curr || acc,
                     false
                   )) {
      return
    }

    annotations = [...annotations, new Annotation(annotation)]

    onAnnotationsChange(annotations.sort((a, b) => a.startSecond - b.startSecond))
  }

  const handleClickClear = () => {
    const currentAnnotation = annotations.find(a => a.timeRangeContains(currentTime))

    if (!currentAnnotation) {
      return
    }

    handleShapesChange(currentAnnotation.id)(
      [])
  }

  const handleDeleteAnnotation = (annotationId: string) => {

    onAnnotationsChange(annotations.filter(a => a.id !== annotationId))
  }

  return {
    tool,
    handleShapesChange,
    handleToolChange,
    handleTimeRangeChange,
    handleDragLayerHandle,
    stageMapRef,
    handleAddAnnotation,
    handleDeleteAnnotation,
    handleClickClear
  }
}

export default useWhiteBoardModel;
