import React, {useState} from "react";
import {Layout, Menu, Space, Tag} from "antd";
import RouteConstants from "../../util/RouteConstants";
import {useHistory} from "react-router-dom";
import logo from "../../resources/logo_with_text.svg";
import PortalSessionService from "../../services/portalsession/PortalSessionService";
import {Key} from "antd/lib/table/interface";
import GuideModal from "../../components/GuideModal/GuideModal";

const {Content, Header, Sider} = Layout;

interface Props {

}

export const AppLayout: React.FC<Props> = props => {

  const history = useHistory();

  const [isGuideVisible, setIsGuideVisible] = useState(false)
  const [openKeys, setOpenKeys] = useState<string[]>([])

  const handleClickManageSubscription = async () => {
    const portalSessionUrl = await PortalSessionService.createPortalSession()

    if (portalSessionUrl) {
      window.location.href = portalSessionUrl
    }
  }

  const handleOpenGuideMenuChange = (keys: Key[]) => {
    setOpenKeys(keys.length > 0 ? keys.map(k => k as string).filter(k => k !== openKeys[0]) : [])
  }

  return (
    <Layout>
      <Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '10vh',
          alignItems: 'center'
        }}
      >

        <div
          className="logo"
          style={{
            margin: 8,
            display: 'flex'
          }}
        >
          <Space style={{height: '100%'}}>
            <img
              style={{
                height: '8vh'
              }}
              src={logo}
              alt=""
            />
            <Tag
              style={{
                background: 'rgba(207, 19, 34, .3)',
                borderColor: 'rgba(207, 19, 34, .3)'
              }}
              color="red"
            >Beta</Tag>
          </Space>
        </div>
        <Menu
          mode="horizontal"
          theme="dark"
        >
          <Menu.Item
            key="3"
            onClick={() => {
              setIsGuideVisible(true)
            }}
          >
            Guide
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={handleClickManageSubscription}
          >
            Manage Subscription
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => {
              localStorage.removeItem('xsrf-token')
              history.push(RouteConstants.LANDING_PAGE)
            }}
          >Logout</Menu.Item>
        </Menu>
      </Header>
      <Layout>
        <Sider
          collapsible={true}
        >
          <Menu theme="dark">
            {/*<Menu.Item*/}
            {/*  disabled*/}
            {/*  key="1"*/}
            {/*>Games <Tag*/}
            {/*  style={{*/}
            {/*    background: 'rgba(207, 19, 34, .3)',*/}
            {/*    borderColor: 'rgba(207, 19, 34, .3)'*/}
            {/*  }}*/}
            {/*  color="red"*/}
            {/*>Coming Soon</Tag></Menu.Item>*/}
            <Menu.Item
              key="2"
              onClick={() => history.push(RouteConstants.CLIPS)}
            >Clips</Menu.Item>
          </Menu>
        </Sider>
        <Content
          style={{
            padding: 24,
            margin: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <GuideModal
            visible={isGuideVisible}
            openKeys={openKeys}
            onOpenChange={handleOpenGuideMenuChange}
            onCancel={() => setIsGuideVisible(false)}
          />
          {props.children}
        </Content>
      </Layout>
    </Layout>
  )
}
