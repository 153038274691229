import VideoPlayer from "../VideoPlayer";
import React, {PropsWithChildren, useRef} from "react";
import ReactPlayer from "react-player";
import {Layer, Line, Stage} from "react-konva";
import WhiteboardArrow from "../whiteboard/shapes/WhiteboardArrow";
import WhiteboardLine from "../whiteboard/shapes/WhiteboardLine";
import WhiteboardBlock from "../whiteboard/shapes/WhiteboardBlock";
import {AnnotationShapeType} from "../../../types/annotation/AnnotationShape";
import Annotation from "../../../types/annotation/Annotation";
import WhiteboardZone from "../whiteboard/shapes/WhiteboardZone";

interface Props {
  url: string
  startTime: number
  layer?: Annotation
}

function TimelineFrame({
  url,
  startTime,
  layer
}: PropsWithChildren<Props>) {

  const videoPlayerRef = useRef<ReactPlayer>(null);

  const onPlayerReady = () => {
    videoPlayerRef.current && (startTime || startTime === 0) && videoPlayerRef.current.seekTo(startTime, 'seconds')
  }

  const positionStyle: React.CSSProperties = layer ? {position: 'absolute'} : {position: 'relative'}

  return (<div
    style={{
      display: "flex",
      position: 'relative',
      height: 180,
      width: 320,
      left: 0
    }}
  >
    <VideoPlayer
      innerRef={videoPlayerRef}
      playing={false}
      url={url}
      volume={0}
      height={180}
      width={320}
      onReady={onPlayerReady}
      style={{
        ...positionStyle,
        top: 0,
        left: 0
      }}
    />
    {layer &&

    <Stage
        width={320}
        height={180}
        style={{zIndex: 5}}
    >
        <Layer>
          {layer.shapes.map(shape => {
            if (shape.type === AnnotationShapeType.FREEHAND) {
              return <Line
                points={shape.points.map(point => point * (180 || 720) / 720)}
                stroke="red"
                globalCompositeOperation="source-over"
              />
            } else if (shape.type === AnnotationShapeType.LINE) {
              return <WhiteboardLine
                points={shape.points.map(point => point * (180 || 720) / 720)}
                color={shape.color}
                scale={180 / 720}
                tension={shape.tension}
              />
            } else if (shape.type === AnnotationShapeType.BLOCK) {
              return <WhiteboardBlock
                points={shape.points.map(point => point * (180 || 720) / 720)}
                color={shape.color}
                blockAngle={shape.blockAngle || 0}
                blockLength={15}
                scale={(180 / 720)}
                tension={shape.tension}
              />
            } else if (shape.type === AnnotationShapeType.ERASER) {
              return <Line
                points={shape.points.map(point => point * (180 || 720) / 720)}
                strokeWidth={20 * (180 || 720)}
                stroke="red"
                lineCap="round"
                globalCompositeOperation="destination-out"
              />
            } else if (shape.type === AnnotationShapeType.ARROW) {
              return <WhiteboardArrow
                points={shape.points.map(point => point * (180 || 720) / 720)}
                color={shape.color}
                scale={(180 / 720)}
                tension={shape.tension}
              />
            } else if (shape.type === AnnotationShapeType.ZONE) {
              return <WhiteboardZone
                points={shape.points.map(point => point * (180 || 720) / 720)}
                color={shape.color}
                scale={(180 / 720)}
              />
            } else {
              return <></>
            }
          })}
        </Layer>
    </Stage>}
  </div>)
}

export default TimelineFrame
