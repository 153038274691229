import React, {useRef, useState} from "react";
import {Text, Transformer} from "react-konva"
import {RGBColor} from "react-color";
import Konva from "konva";
import {Html} from "react-konva-utils";
import {Button, Checkbox, InputNumber, Popover, Space} from "antd";
import {BgColorsOutlined, CheckOutlined} from "@ant-design/icons";
import rgbColorToCssString from "../../../../util/RgbColorToCssString";
import UserPreferences from "../../../../types/userpreferences/UserPreferences";
import {KonvaEventObject} from "konva/lib/Node";
import ColorPicker from "../../../ColorPicker/ColorPicker";

interface Props {
  points: number[]
  color: RGBColor
  isSelected?: boolean
  onSelect?: () => void
  onShapeChange?: (points: number[]) => void
  scale?: number
  text?: string
  fontFamily?: string
  fontSize?: number
  onTextChange: (text?: string, fontFamily?: string, fontSize?: number, color?: RGBColor) => void
  userPreferences: UserPreferences | null
  onUserPreferencesChange: (userPreferences: UserPreferences) => void
  onRecentColorsChange: (colors: RGBColor[]) => void
  onDefaultFontSizeChange: () => void
}

function WhiteboardText({
  points,
  color,
  isSelected,
  onSelect,
  onShapeChange,
  scale,
  text,
  fontFamily,
  fontSize,
  onTextChange,
  userPreferences,
  onUserPreferencesChange,
  onRecentColorsChange,
  onDefaultFontSizeChange
}: React.PropsWithChildren<Props>) {

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const transformerRef = useRef<Konva.Transformer>(null)
  const textRef = useRef<Konva.Text>(null)

  React.useEffect(
    () => {
      if (!isSelected) {
        setIsEditing(false)
      }

      if (isSelected && transformerRef.current && textRef.current) {
        // we need to attach transformer manually
        transformerRef.current.nodes([textRef.current]);
        transformerRef.current.getLayer()
                      ?.batchDraw();
      }
    },
    [isSelected]
  );

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      setIsEditing(false)
    }
  }

  return (<>
    <Text
      ref={textRef}
      onClick={onSelect}
      onTap={onSelect}
      x={points[0]}
      y={points[1]}
      fill={rgbColorToCssString(color)}
      fontSize={fontSize}
      fontStyle="normal"
      text={text}
      draggable
      width={points[2] - points[0]}
      onDblClick={() => {
        setIsEditing(true)
        onSelect && onSelect()
      }}
      visible={!isEditing}
      onDragMove={(e: KonvaEventObject<DragEvent>) => {
        onShapeChange && onShapeChange([e.target.x(), e.target.y(), e.target.x() + e.target.width(), e.target.y()])
      }}
      fontFamily="Roboto"
    />
    {isEditing && isSelected && <Html
        divProps={{
          style: {
            width: '100%',
            height: '100%',
          }
        }}
    >
        <Popover
            content={<Space>
              <div>
                <Space>
                  <InputNumber
                    defaultValue={fontSize}
                    min={10}
                    max={72}
                    onChange={e => onTextChange(
                      text,
                      fontFamily,
                      e,
                      color
                    )}
                    style={{
                      width: 60
                    }}
                  />
                  <Checkbox
                    checked={fontSize === userPreferences?.fontSize}
                    disabled={fontSize === userPreferences?.fontSize}
                    onClick={onDefaultFontSizeChange}
                  >
                    Default
                  </Checkbox>
                </Space>
              </div>
              <div>
                <Popover
                  placement="right"
                  content={
                    <ColorPicker
                      color={color}
                      onColorChange={(newColor) => onTextChange(text, fontFamily, fontSize, newColor)}
                      userPreferences={userPreferences}
                      onRecentColorsChange={onRecentColorsChange}
                    ></ColorPicker>
                  }
                >
                  <Button
                    icon={<BgColorsOutlined/>}
                    style={{border: 0}}
                    title="Change Color"
                  />
                </Popover>
              </div>
              <Button
                icon={<CheckOutlined/>}
                style={{border: 0}}
                title="Done"
                onClick={() => setIsEditing(false)}
              />
            </Space>}
        >
            <div
                style={{
                  height: textRef.current?.height(),
                  position: 'absolute',
                  left: points[0],
                  top: points[1],
                  width: points[2] - points[0],
                  background: 'none',
                }}
            >
            <textarea
                defaultValue={text}
                style={{
                  position: 'relative',
                  top: -4,
                  left: 0,
                  width: 'inherit',
                  height: 'fit-content',
                  background: 'none',
                  padding: 0,
                  resize: 'none',
                  border: 'none',
                  outline: "none",
                  fontSize: fontSize,
                  fontWeight: 400,
                  color: rgbColorToCssString(color),
                  fontFamily: 'Roboto'
                }}
                onChange={e => onTextChange(
                  e.target.value,
                  fontFamily,
                  fontSize,
                  color
                )}
                onKeyPress={handleKeyPress}
            />
            </div>
        </Popover>
    </Html>}
    {isSelected && <Transformer
        ref={transformerRef}
        enabledAnchors={["middle-left", "middle-right"]}
        rotateEnabled={false}
        anchorSize={10}
        anchorCornerRadius={5}
        anchorStrokeWidth={0}
        onTransform={(e => {
          e.target.width(Math.max(e.target.width() * e.target.scale().x, 100))

          if (e.target.width() === 100) {
            e.target.x(points[0])
          }

          e.target.scale({x: 1,y: 1})
          onShapeChange && onShapeChange([e.target.x(), e.target.y(), e.target.x() + e.target.width(), e.target.y()])
        })}
        visible={!isEditing}
    />}
  </>)
}

export default WhiteboardText;
