import FilmServiceClient from "../FilmServiceClient";
import CheckoutSessionDto from "./CheckoutSessionDto";

const createCheckoutSession = async (priceId: string) => {

  let checkoutSession: CheckoutSessionDto | null = null

  try {
    // Redirects on success
    const resp = await FilmServiceClient.post<CheckoutSessionDto>('/checkout-session', {priceId})

    checkoutSession = resp.data
  } catch (e) {

  }

  return checkoutSession

}

const CheckoutSessionService = {
  createCheckoutSession
}

export default CheckoutSessionService
