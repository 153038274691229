import UserPreferencesDto from "../../services/userpreferences/UserPreferencesDto";
import ColorPalette from "./ColorPalette";
import ColorPaletteDto from "../../services/userpreferences/ColorPaletteDto";

export default class UserPreferences {

  id?: string
  strokeWidth: number
  lineTension: number
  fontSize: number
  colorPalettes: Map<string,ColorPalette>

  constructor(dto: UserPreferencesDto) {
    this.id = dto.id
    this.strokeWidth = dto.strokeWidth
    this.lineTension = dto.lineTension
    this.fontSize = dto.fontSize
    this.colorPalettes = new Map<string, ColorPalette>()

    Object.keys(dto.colorPalettes).forEach(key => {
      this.colorPalettes.set(key, new ColorPalette(dto.colorPalettes[key]))
    })
  }

  asDto() {

    const dtoColorPalettes: { [name: string]: ColorPaletteDto } = {}

    Array.from(this.colorPalettes).forEach((entry: [key: string, value: ColorPalette]) => {
      dtoColorPalettes[entry[0]] = entry[1].asDto()
    })

    return {
      id: this.id,
      strokeWidth: this.strokeWidth,
      lineTension: this.lineTension,
      fontSize: this.fontSize,
      colorPalettes: dtoColorPalettes,
    } as UserPreferencesDto
  }
}
