import {useRef} from "react";
import Annotation from "../../types/annotation/Annotation";
import {AnnotationShapeType} from "../../types/annotation/AnnotationShape";

interface ClipEditSnapshot {
  trimRange: number[]
  annotations: Annotation[]
}

function useClipEditHistory(trimRange: number[] | null,
  annotations: Annotation[],
  onTrimRangeChange: (start: number, end: number) => void,
  onAnnotationsChange: (annotations: Annotation[]) => void,
) {

  const undoHistoryRef = useRef<ClipEditSnapshot[]>([])

  const redoHistoryRef = useRef<ClipEditSnapshot[]>([])

  const handleUndo = () => {
    const previousSnapshot = undoHistoryRef.current.pop()

    if (!previousSnapshot) {
      return
    }

    redoHistoryRef.current.push(previousSnapshot)

    if (previousSnapshot.trimRange[0] !== (trimRange && trimRange[0]) || previousSnapshot.trimRange[1] !==
      (trimRange && trimRange[1])) {
      onTrimRangeChange(
        previousSnapshot.trimRange[0],
        previousSnapshot.trimRange[1]
      )
    } else {
      onAnnotationsChange(previousSnapshot.annotations)
    }
  }

  const handleRedo = () => {

    const nextSnapshot = redoHistoryRef.current.pop()

    if (!nextSnapshot) {
      return
    }

    undoHistoryRef.current.push(nextSnapshot)

    if (nextSnapshot.trimRange[0] !== (trimRange && trimRange[0]) || nextSnapshot.trimRange[1] !==
      (trimRange && trimRange[1])) {
      onTrimRangeChange(
        nextSnapshot.trimRange[0],
        nextSnapshot.trimRange[1]
      )
    } else {
      onAnnotationsChange(nextSnapshot.annotations)
    }
  }

  const updateHistory = (snapshot: ClipEditSnapshot) => {
    undoHistoryRef.current.push(snapshot)
    redoHistoryRef.current = []
  }


  const handleTrimRangeChange = (values: number[]) => {
    updateHistory({
      trimRange: [values[0], values[1]],
      annotations
    })

    onTrimRangeChange(
      values[0],
      values[1]
    )
  }

  const pushAnnotations = (newAnnotations: Annotation[]) => {

    updateHistory({
      trimRange: trimRange || [0, 1],
      annotations: newAnnotations
    })

    onAnnotationsChange(newAnnotations)
  }

  const handleAnnotationsChange = (newAnnotations: Annotation[]) => {

    const previousAnnotationState = undoHistoryRef.current.length > 0 && undoHistoryRef.current[undoHistoryRef.current.length - 1].annotations

    if (!previousAnnotationState) {
      pushAnnotations(newAnnotations)
      return
    }

    const annotationAddingLine = newAnnotations.find(a => a.shapes.find(s => s.type === AnnotationShapeType.NEW_POINT_LINE))

    if (!annotationAddingLine) {
      pushAnnotations(newAnnotations)
      return
    }

    const previousAnnotation = previousAnnotationState.find(a => a.id === annotationAddingLine.id)

    const newAnnotationsClone = newAnnotations.map(a => new Annotation(a.asDto()))

    const annotationAddingLineClone = newAnnotationsClone.find(a => a.id === annotationAddingLine.id)!

    annotationAddingLineClone.shapes = annotationAddingLineClone.shapes.filter(s => s.type !== AnnotationShapeType.NEW_POINT_LINE)

    const pointsChanged = previousAnnotation?.shapes.flatMap(s => s.points).length !== annotationAddingLineClone.shapes.flatMap(s => s.points).length

    if (pointsChanged) {
      pushAnnotations(newAnnotationsClone)
      return
    }
    onAnnotationsChange(newAnnotations)
  }

  return {
    handleUndo,
    handleRedo,
    updateHistory,
    handleTrimRangeChange,
    handleAnnotationsChange
  }
}

export default useClipEditHistory
