import {Button, Popover, Space, Tooltip} from "antd";
import React, {MouseEventHandler, PropsWithChildren, useCallback, useEffect, useRef, useState} from "react";
import TimelineSegment, {Segment} from "./TimelineSegment";
import {PlusOutlined} from "@ant-design/icons";
import {calculateElapsedPauseSeconds} from "../FilmVideoPlayer";
import GrabHorizontalOutlined from "../../icons/GrabHorizontalOutlined/GrabHorizontalOutlined";

interface Props {
  value: number
  min: number
  max: number
  onClick: (time: number) => void
  url: string
  segments: Segment[]
  trimRange: number[] | null
  onAnnotationTimeRangeChange: (id: string) => (values: number[]) => void
  onTrimRangeChange: (value: number[]) => void
  onAddAnnotation?: (startTime: number) => Promise<void>
  onDeleteAnnotation?: (annotationId: string) => void
  setIsDragging: (isDragging: boolean) => void
}

function MediaSlider({
  value,
  min,
  max,
  onClick,
  url,
  segments,
  trimRange,
  onAnnotationTimeRangeChange,
  onTrimRangeChange,
  setIsDragging,
  onAddAnnotation,
  onDeleteAnnotation,
}: PropsWithChildren<Props>) {

  const mouseDownRef = useRef(false)
  const divRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const draggableMouseOverElement = useRef<{ layerId: string, handle: 'start' | 'end' } | null>(null)
  const initialHandleValue = useRef<number | null>(null)
  const draggableMouseOverTrimHandle = useRef<'start' | 'end' | null>(null);
  const mouseDragStartTrimEndDiff = useRef<number | null>(null)
  const [markerPosition, setMarkerPosition] = useState<number>(0)

  const handleMouseUp = useCallback(
    () => {
      mouseDownRef.current = false
      draggableMouseOverElement.current = null
      draggableMouseOverTrimHandle.current = null
      setIsDragging(false)
    },
    [mouseDownRef, setIsDragging]
  )

  const handleMouseMove = useCallback((event: MouseEvent) => {

    if (!mouseDownRef.current || !divRef.current) {
      return
    }

    const newValue = (event.pageX - divRef.current.getBoundingClientRect().x + divRef.current.scrollLeft) /
      (divRef.current.scrollWidth - 24) * (max - min)

    let valueAdjustment = 0

    if (draggableMouseOverElement.current?.handle === 'end') {
      valueAdjustment = 160
    } else if (draggableMouseOverElement.current?.handle === 'start') {
      valueAdjustment = -160
    } else if (draggableMouseOverTrimHandle.current === 'start') {
      valueAdjustment = 160
    } else if (draggableMouseOverTrimHandle.current === 'end') {
      valueAdjustment = -160
    } else {
      // do nothing
    }

    onClick(newValue + valueAdjustment)
    setMarkerPosition(newValue + valueAdjustment)

    if (draggableMouseOverElement.current) {

      if (draggableMouseOverElement.current.handle === 'end') {
        const layer = segments.find(segment => segment.layer?.id === draggableMouseOverElement.current?.layerId)?.layer

        if (!layer) {
          return
        }

        const newEndValue = newValue + 160

        const changeInValue = newEndValue - layer.endSecond * 1000

        onAnnotationTimeRangeChange(draggableMouseOverElement.current.layerId)([
          layer.startSecond * 1000, Math.max(newEndValue, layer.startSecond * 1000)
        ])

        segments.filter(s => s.layer && s.layer.startSecond > layer.endSecond)
                .map(s => s.layer!)
                .forEach(l => {
                  onAnnotationTimeRangeChange(l.id)([
                    l.startSecond * 1000 + changeInValue, l.endSecond * 1000 + changeInValue
                  ])
                })

        trimRange && onTrimRangeChange([
          trimRange[0], Math.max(
            newValue,
            layer.startSecond * 1000
          ) / 1000 + (mouseDragStartTrimEndDiff.current || 0)
        ])
      }

      if (draggableMouseOverElement.current.handle === 'start') {
        const segment = segments.find(segment => segment.layer?.id === draggableMouseOverElement.current?.layerId)

        const layer = segment?.layer

        if (!segment || !layer) {
          return
        }

        const previousLayerEnd = segments.find((seg, i, segs) => i === segs.indexOf(segment) - 2)?.layer?.endSecond ||
          Number.NEGATIVE_INFINITY

        onAnnotationTimeRangeChange(draggableMouseOverElement.current.layerId)(
          [
            Math.max(
              Math.min(
                newValue - 160,
                layer.endSecond * 1000
              ),
              previousLayerEnd * 1000 + 1,
              0
            ), layer.endSecond * 1000
          ])


        trimRange && onTrimRangeChange([
          trimRange[0],
          Math.min(
            trimRange[1],
            (max - newValue - 160 + layer.startSecond * 1000) / 1000
          )
        ])

      }
    }

    if (draggableMouseOverTrimHandle.current === 'start') {

      trimRange && onTrimRangeChange([
        Math.max(
          Math.min(
            (newValue + 160) / 1000,
            trimRange[1]
          ),
          0
        ), trimRange[1]
      ])
    }

    if (draggableMouseOverTrimHandle.current === 'end') {

      trimRange && onTrimRangeChange([
        trimRange[0], Math.min(
          Math.max(
            (newValue - 160) / 1000,
            trimRange[0]
          ),
          max / 1000
        ),
      ])
    }
  }, [max, min, onAnnotationTimeRangeChange, onClick, onTrimRangeChange, segments, trimRange])

  const handleMouseDown: MouseEventHandler<HTMLDivElement> = (event: React.MouseEvent) => {
    mouseDownRef.current = true
    const layer = segments.find(segment => segment.layer?.id === draggableMouseOverElement.current?.layerId)?.layer
    mouseDragStartTrimEndDiff.current = layer && trimRange ? trimRange[1] - layer.endSecond : null;

    setIsDragging(true)


  }

  useEffect(() => {
    document.addEventListener(
      'mouseup',
      handleMouseUp
    )
    document.addEventListener(
      'mousedown',
      handleMouseMove
    )
    document.addEventListener(
      'mousemove',
      handleMouseMove
    )

    return () => {

      document.removeEventListener(
        'mouseup',
        handleMouseUp
      )
      document.removeEventListener(
        'mousedown',
        handleMouseMove
      )
      document.removeEventListener(
        'mousemove',
        handleMouseMove
      )
    }
    },
    [max, trimRange, segments, handleMouseMove, handleMouseUp]
  )

  const handleMouseEnterExitDraggable = (elementId: string | null, handle?: 'start' | 'end') => {

    if (!mouseDownRef.current) {
      draggableMouseOverElement.current = !elementId ? null : {
        layerId: elementId,
        handle: handle!
      }
      initialHandleValue.current = null
    }

    if (!initialHandleValue.current) {
      initialHandleValue.current = segments.find(segment => segment.layer?.id === draggableMouseOverElement.current?.layerId)?.layer?.endSecond || null
    }
  }

  const handleMouseEnterExitTrimHandle = (handle: 'start' | 'end' | null) => {
    if (mouseDownRef.current) {
      return;
    }

    if (!handle) {
      draggableMouseOverTrimHandle.current = null
      return
    }

    draggableMouseOverTrimHandle.current = handle
  }

  const handleAddAnnotation = () => {

    if (!onAddAnnotation) {
      return
    }

    onAddAnnotation((markerPosition + calculateElapsedPauseSeconds(
      markerPosition,
      segments.filter(s => s.layer)
              .map(s => s.layer!)
    )) / 1000)
  }

  const handleDeleteAnnotation = (annotationId: string) => () => {
    onDeleteAnnotation && onDeleteAnnotation(annotationId)
  }

  return (<div
    ref={containerRef}
    //onClick={handleOnClick}
    style={{
      position: 'relative',
      overflow: 'scroll',
      height: 216,
      width: 1280,
      paddingLeft: 32,
    }}
  >
    <div
      ref={divRef}
      onMouseDown={handleMouseDown}
      draggable={false}
      style={{
        width: 'fit-content',
        height: 216,
        position: 'relative',
        paddingRight: 24
      }}
    >
      <Space
        size={0}
      >
        {segments.map(segment => <TimelineSegment
          segment={segment}
          url={url}
          onMouseEnterExitDraggable={handleMouseEnterExitDraggable}
          onDelete={segment.layer && handleDeleteAnnotation(segment.layer.id)}
        />)}
      </Space>

      <div
        style={{
          position: 'absolute',
          top: 16,
          left: Math.min(
            Math.max(
              divRef.current ? value / (max - min) * (divRef.current.scrollWidth - 24 - 2) : 0,
              0
            ),
            divRef.current?.scrollWidth ? divRef.current.scrollWidth - 24 - 2 : 0
          ),
          backgroundColor: '#f1bf37',
          height: 180,
          width: 4,
          zIndex: 10
        }}
      />
      <Popover
        content={<Space>
          <Tooltip title="Add Annotation">
            <Button
              disabled={segments.map(s => s.layer)
                                .map(a => a ? a.timeRangeContains(markerPosition / 1000) : false)
                                .reduce(
                                  (acc, curr) => acc || curr,
                                  false
                                )}
              icon={<PlusOutlined/>}
              type="text"
              onClick={handleAddAnnotation}
            />
          </Tooltip>
        </Space>}
      >
        <div
          className="hoverable draggable"
          style={{
            position: 'absolute',
            top: 16,
            left: Math.min(
              Math.max(
                divRef.current ? markerPosition / (max - min) * (divRef.current.scrollWidth - 24 - 2) : 0,
                0
              ),
              (divRef.current?.scrollWidth ? divRef.current.scrollWidth - 24 - 2 : 0)
            ),
            backgroundColor: 'rgba(241, 191, 55, 0.4)',
            height: 180,
            width: 4,
            zIndex: 10
          }}
        />
      </Popover>
      {trimRange && <>
          <div
              style={{
                position: 'absolute',
                top: 16,
                backgroundColor: '#ff4d4f',
                height: 180,
                opacity: 0.8,
                width: Math.max(
                  divRef.current ? (trimRange[0] / (max - min) * (divRef.current.scrollWidth - 24) * 1000) : 0,
                  0
                ),
                zIndex: 10,
              }}
          />
          <Button
              className="hoverable draggable"
              style={{
                position: 'absolute',
                top: 0,
                left: Math.max(
                  divRef.current ? (trimRange[0] / (max - min) * (divRef.current.scrollWidth - 24) * 1000) - 24 : -24,
                  -24
                ),
                zIndex: 10,
                transition: "none",
                borderRadius: '2px 2px 0 0',
                height: 16,
                width: 24
              }}
              icon={<GrabHorizontalOutlined
                style={{
                  fontSize: 28,
                  height: '100%',
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: 'center',
                  width: '100%'
                }}
              />}
              type="primary"
              onMouseEnter={() => handleMouseEnterExitTrimHandle('start')}
              onMouseLeave={() => handleMouseEnterExitTrimHandle(null)}
              danger
          />
          <Button
              className="hoverable draggable"
              style={{
                position: 'absolute',
                top: 0,
                left: Math.max(
                  Math.min(
                    divRef.current ? (trimRange[1] / (max - min) * (divRef.current.scrollWidth - 24) * 1000) : 0,
                    divRef.current ? divRef.current.scrollWidth - 24 : max
                  ),
                  0
                ),
                zIndex: 10,
                transition: 'none',
                borderRadius: '2px 2px 0 0',
                height: 16,
                width: 24
              }}
              icon={<GrabHorizontalOutlined
                style={{
                  fontSize: 28,
                  height: '100%',
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: 'center',
                  width: '100%'
                }}
              />}
              type="primary"
              onMouseEnter={() => handleMouseEnterExitTrimHandle('end')}
              onMouseLeave={() => handleMouseEnterExitTrimHandle(null)}
              danger
          />
          <div
              style={{
                position: 'absolute',
                top: 16,
                right: 24,
                backgroundColor: '#ff4d4f',
                height: 180,
                opacity: 0.8,
                width: Math.max(
                  Math.min(
                    divRef.current ? (divRef.current.scrollWidth - 24) - trimRange[1] / (max - min) *
                      (divRef.current.scrollWidth - 24) * 1000 : 0,
                    max
                  ),
                  0
                ),
                zIndex: 10,
              }}
          />
      </>}
    </div>
  </div>)
}

export default MediaSlider;
